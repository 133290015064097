<template>
  <div class="publish-schema-page">
    <div class="publish-schema-page-content">
      <slot name="pageContent" />
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.publish-schema-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;

  .publish-schema-page-content {
    overflow: auto;
    padding-top: 4px;
  }
}

</style>
