<!-- eslint-disable max-len -->
<template>
  <svg
    width="250"
    height="200"
    viewBox="0 0 250 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="250"
      height="200"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M207 65C210.866 65 214 68.134 214 72C214 75.866 210.866 79 207 79H167C170.866 79 174 82.134 174 86C174 89.866 170.866 93 167 93H189C192.866 93 196 96.134 196 100C196 103.866 192.866 107 189 107H178.826C173.952 107 170 110.134 170 114C170 116.577 172 118.911 176 121C179.866 121 183 124.134 183 128C183 131.866 179.866 135 176 135H93C89.134 135 86 131.866 86 128C86 124.134 89.134 121 93 121H54C50.134 121 47 117.866 47 114C47 110.134 50.134 107 54 107H94C97.866 107 101 103.866 101 100C101 96.134 97.866 93 94 93H69C65.134 93 62 89.866 62 86C62 82.134 65.134 79 69 79H109C105.134 79 102 75.866 102 72C102 68.134 105.134 65 109 65H207ZM207 93C210.866 93 214 96.134 214 100C214 103.866 210.866 107 207 107C203.134 107 200 103.866 200 100C200 96.134 203.134 93 207 93Z"
      fill="#F3F7FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M153.671 64.0007L162.973 131.843L163.809 138.65C164.078 140.842 162.519 142.838 160.326 143.107L101.766 150.298C99.5733 150.567 97.5776 149.008 97.3083 146.815L88.2926 73.3874C88.158 72.2911 88.9376 71.2932 90.0339 71.1586C90.0408 71.1578 90.0478 71.1569 90.0547 71.1562L94.9131 70.6112M98.8416 70.1705L103.429 69.656L98.8416 70.1705Z"
      fill="white"
    />
    <path
      d="M154.91 63.8309C154.816 63.1469 154.185 62.6685 153.502 62.7623C152.818 62.856 152.339 63.4865 152.433 64.1705L154.91 63.8309ZM162.973 131.843L164.214 131.691C164.213 131.685 164.212 131.679 164.212 131.674L162.973 131.843ZM163.809 138.65L165.05 138.497L163.809 138.65ZM160.326 143.107L160.479 144.348L160.326 143.107ZM101.766 150.298L101.918 151.538L101.766 150.298ZM97.3083 146.815L98.549 146.663L97.3083 146.815ZM90.0547 71.1562L90.1941 72.3984L90.0547 71.1562ZM95.0524 71.8534C95.7385 71.7764 96.2322 71.1579 96.1553 70.4718C96.0783 69.7858 95.4598 69.292 94.7737 69.369L95.0524 71.8534ZM98.7023 68.9283C98.0162 69.0053 97.5224 69.6238 97.5994 70.3098C97.6764 70.9959 98.2949 71.4897 98.981 71.4127L98.7023 68.9283ZM103.568 70.8982C104.254 70.8212 104.748 70.2027 104.671 69.5166C104.594 68.8306 103.975 68.3368 103.289 68.4137L103.568 70.8982ZM152.433 64.1705L161.735 132.013L164.212 131.674L154.91 63.8309L152.433 64.1705ZM161.733 131.996L162.568 138.802L165.05 138.497L164.214 131.691L161.733 131.996ZM162.568 138.802C162.753 140.31 161.681 141.682 160.174 141.867L160.479 144.348C163.356 143.995 165.403 141.375 165.05 138.497L162.568 138.802ZM160.174 141.867L101.614 149.057L101.918 151.538L160.479 144.348L160.174 141.867ZM101.614 149.057C100.106 149.242 98.7341 148.17 98.549 146.663L96.0677 146.967C96.421 149.845 99.0404 151.892 101.918 151.538L101.614 149.057ZM98.549 146.663L89.5332 73.2351L87.0519 73.5398L96.0677 146.967L98.549 146.663ZM89.5332 73.2351C89.4828 72.824 89.7751 72.4498 90.1863 72.3993L89.8816 69.9179C88.1 70.1367 86.8331 71.7582 87.0519 73.5398L89.5332 73.2351ZM90.1863 72.3993C90.1889 72.399 90.1915 72.3987 90.1941 72.3984L89.9154 69.914C89.9041 69.9152 89.8928 69.9165 89.8816 69.9179L90.1863 72.3993ZM90.1941 72.3984L95.0524 71.8534L94.7737 69.369L89.9154 69.914L90.1941 72.3984ZM98.981 71.4127L103.568 70.8982L103.289 68.4137L98.7023 68.9283L98.981 71.4127Z"
      fill="#1F64E7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M151.141 68.2686L159.56 129.752L160.318 135.921C160.562 137.908 159.168 139.714 157.204 139.955L104.762 146.394C102.798 146.635 101.009 145.22 100.765 143.233L92.6148 76.8562C92.4802 75.7599 93.2598 74.762 94.3562 74.6274L100.844 73.8308"
      fill="#E8F0FE"
    />
    <path
      d="M107.922 54C107.922 52.4812 109.153 51.25 110.672 51.25H156.229C156.958 51.25 157.657 51.5395 158.173 52.0549L171.616 65.4898C172.132 66.0056 172.422 66.7053 172.422 67.4349V130C172.422 131.519 171.191 132.75 169.672 132.75H110.672C109.153 132.75 107.922 131.519 107.922 130V54Z"
      fill="white"
      stroke="#1F64E7"
      stroke-width="2.5"
    />
    <path
      d="M156.672 52.4023V63.9995C156.672 65.6564 158.015 66.9995 159.672 66.9995H167.605"
      stroke="#1F64E7"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M118 118H144M118 67H144H118ZM118 79H161H118ZM118 92H161H118ZM118 105H161H118Z"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M202.869 105.949V109.009"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M202.869 113.09V116.15"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M197.949 111.05H200.901"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M204.838 111.05H207.79"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M162.033 158.48V160.52"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M162.033 164.6V166.64"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M158.098 162.561H160.066"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M164.002 162.561H165.97"
      stroke="#75A4FE"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M197.069 149.875C197.753 149.006 198.368 148.098 198.914 147.158C200.115 145.09 200.98 142.869 201.508 140.578C202.043 138.262 202.234 135.875 202.079 133.506C201.944 131.438 201.546 129.384 200.884 127.401C200.227 125.434 199.311 123.537 198.134 121.768C197.432 120.714 196.638 119.705 195.752 118.753C194.45 117.356 193.022 116.162 191.505 115.171C189.875 114.106 188.142 113.276 186.351 112.682C184.108 111.938 181.776 111.563 179.443 111.561C177.628 111.558 175.813 111.781 174.039 112.23C172.043 112.735 170.1 113.526 168.27 114.605C166.662 115.553 165.141 116.723 163.749 118.116C162 119.867 160.583 121.841 159.5 123.953C158.439 126.021 157.699 128.221 157.279 130.476C156.846 132.798 156.754 135.178 157.004 137.529C157.329 140.575 158.227 143.572 159.703 146.332C160.648 148.097 161.829 149.766 163.247 151.288C165.498 153.704 168.126 155.514 170.941 156.714C174.364 158.173 178.063 158.73 181.696 158.378C184.745 158.083 187.747 157.148 190.501 155.569"
      fill="white"
    />
    <path
      d="M197.069 149.875C197.753 149.006 198.368 148.098 198.914 147.158C200.115 145.09 200.98 142.869 201.508 140.578C202.043 138.262 202.234 135.875 202.079 133.506C201.944 131.438 201.546 129.384 200.884 127.401C200.227 125.434 199.311 123.537 198.134 121.768C197.432 120.714 196.638 119.705 195.752 118.753C194.45 117.356 193.022 116.162 191.505 115.171C189.875 114.106 188.142 113.276 186.351 112.682C184.108 111.938 181.776 111.563 179.443 111.561C177.628 111.558 175.813 111.781 174.039 112.23C172.043 112.735 170.1 113.526 168.27 114.605C166.662 115.553 165.141 116.723 163.749 118.116C162 119.867 160.583 121.841 159.5 123.953C158.439 126.021 157.699 128.221 157.279 130.476C156.846 132.798 156.754 135.178 157.004 137.529C157.329 140.575 158.227 143.572 159.703 146.332C160.648 148.097 161.829 149.766 163.247 151.288C165.498 153.704 168.126 155.514 170.941 156.714C174.364 158.173 178.063 158.73 181.696 158.378C184.745 158.083 187.747 157.148 190.501 155.569"
      stroke="#1F64E7"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M192.144 154.593C193.574 153.639 194.845 152.449 195.672 151.489L192.144 154.593Z"
      fill="white"
    />
    <path
      d="M192.144 154.593C193.574 153.639 194.845 152.449 195.672 151.489"
      stroke="#1F64E7"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      d="M200.164 136.354C200.023 136.357 199.882 136.358 199.741 136.358C187.316 136.358 177.143 126.317 176.29 113.6C166.401 115.217 158.836 124.132 158.836 134.889C158.836 146.791 168.098 156.44 179.523 156.44C190.476 156.44 199.441 147.573 200.164 136.354Z"
      fill="#E8F0FE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M171.639 134.191C171.038 133.615 170.104 133.659 169.554 134.29C169.004 134.921 169.047 135.899 169.648 136.475L176.595 143.124C177.438 143.931 178.745 143.869 179.514 142.986C179.547 142.948 179.547 142.948 179.578 142.91L189.491 130.416C190.012 129.76 189.926 128.784 189.3 128.238C188.673 127.692 187.742 127.782 187.221 128.439L177.901 140.185L171.639 134.191Z"
      fill="white"
      stroke="#1F64E7"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M172.526 115.849C171.567 116.223 170.63 116.676 169.724 117.211C168.317 118.041 166.987 119.065 165.769 120.284C165.27 120.784 164.802 121.304 164.364 121.843M163.012 123.717C162.664 124.262 162.344 124.82 162.051 125.39C161.835 125.81 161.634 126.238 161.449 126.67"
      fill="white"
    />
    <path
      d="M172.956 117.04C173.591 116.793 173.913 116.059 173.675 115.401C173.438 114.744 172.73 114.411 172.096 114.658L172.956 117.04ZM169.724 117.211L170.33 118.316L169.724 117.211ZM165.769 120.284L164.917 119.37L165.769 120.284ZM163.426 121.024C162.989 121.562 163.055 122.365 163.573 122.817C164.092 123.269 164.866 123.199 165.303 122.661L163.426 121.024ZM164.035 124.418C164.409 123.832 164.255 123.043 163.69 122.655C163.125 122.268 162.363 122.429 161.989 123.015L164.035 124.418ZM162.051 125.39L163.135 125.986L162.051 125.39ZM160.328 126.154C160.052 126.796 160.33 127.548 160.949 127.833C161.569 128.118 162.294 127.828 162.57 127.186L160.328 126.154ZM172.096 114.658C171.076 115.055 170.08 115.537 169.118 116.105L170.33 118.316C171.179 117.815 172.057 117.39 172.956 117.04L172.096 114.658ZM169.118 116.105C167.622 116.988 166.209 118.076 164.917 119.37L166.621 121.199C167.764 120.054 169.012 119.094 170.33 118.316L169.118 116.105ZM164.917 119.37C164.387 119.9 163.89 120.452 163.426 121.024L165.303 122.661C165.713 122.156 166.152 121.668 166.621 121.199L164.917 119.37ZM161.989 123.015C161.619 123.594 161.279 124.188 160.968 124.793L163.135 125.986C163.409 125.452 163.709 124.929 164.035 124.418L161.989 123.015ZM160.968 124.793C160.738 125.24 160.525 125.694 160.328 126.154L162.57 127.186C162.744 126.781 162.932 126.381 163.135 125.986L160.968 124.793Z"
      fill="#75A4FE"
    />
  </svg>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
