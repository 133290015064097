<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.99935 4C5.99935 5.10457 6.89478 6 7.99935 6C9.10392 6 9.99935 5.10457 9.99935 4C9.99935 2.89543 9.10392 2 7.99935 2C6.89478 2 5.99935 2.89543 5.99935 4Z"
      fill="currentColor"
    />
    <path
      d="M1.66602 12C1.66602 13.1046 2.56145 14 3.66602 14C4.77058 14 5.66602 13.1046 5.66602 12C5.66602 10.8954 4.77058 10 3.66602 10C2.56145 10 1.66602 10.8954 1.66602 12Z"
      fill="currentColor"
    />
    <path
      d="M12.3327 14C11.2281 14 10.3327 13.1046 10.3327 12C10.3327 10.8954 11.2281 10 12.3327 10C13.4373 10 14.3327 10.8954 14.3327 12C14.3327 13.1046 13.4373 14 12.3327 14Z"
      fill="currentColor"
    />
    <path
      d="M4.80247 5.04154C5.0095 4.85881 5.02921 4.54284 4.84647 4.3358C4.66374 4.12877 4.34777 4.10906 4.14074 4.2918C2.93052 5.35994 2.16602 6.92434 2.16602 8.66668C2.16602 8.94283 2.38987 9.16668 2.66602 9.16668C2.94216 9.16668 3.16602 8.94283 3.16602 8.66668C3.16602 7.22316 3.7982 5.92791 4.80247 5.04154Z"
      fill="currentColor"
    />
    <path
      d="M11.858 4.2918C11.6509 4.10906 11.335 4.12877 11.1522 4.3358C10.9695 4.54284 10.9892 4.85881 11.1962 5.04154C12.2005 5.92791 12.8327 7.22316 12.8327 8.66668C12.8327 8.94283 13.0565 9.16668 13.3327 9.16668C13.6088 9.16668 13.8327 8.94283 13.8327 8.66668C13.8327 6.92434 13.0682 5.35994 11.858 4.2918Z"
      fill="currentColor"
    />
    <path
      d="M6.79064 13.3478C6.52322 13.2789 6.25063 13.4399 6.1818 13.7074C6.11297 13.9748 6.27396 14.2474 6.54139 14.3162C7.00792 14.4363 7.49656 14.5 7.99935 14.5C8.50214 14.5 8.99078 14.4363 9.45731 14.3162C9.72474 14.2474 9.88573 13.9748 9.8169 13.7074C9.74807 13.4399 9.47548 13.2789 9.20805 13.3478C8.82226 13.4471 8.41735 13.5 7.99935 13.5C7.58134 13.5 7.17643 13.4471 6.79064 13.3478Z"
      fill="currentColor"
    />
  </svg>
</template>
