import { allowedStates } from '@/store/helpers/storeState';
import { computed } from 'vue';
import { useStore } from 'vuex';

type Props = {
  storeName: string;
};

export default (
  {
    storeName,
  }: Props,
) => {
  const store = useStore();
  const storeStatus = computed(() => store.getters[`${storeName}/storeStatus`]);
  const isStoreLoading = computed(() => storeStatus.value === allowedStates.IS_LOADING);
  const isStoreBlank = computed(() => storeStatus.value === allowedStates.IS_BLANK);

  const isStoreEmpty = computed(() => isStoreLoading.value || isStoreBlank.value);

  return {
    isStoreEmpty,
  };
};
