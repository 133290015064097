<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.0126 11.0002C11.1293 10.9092 11.2351 10.8034 11.4467 10.5918L14.085 7.95351C14.1488 7.88973 14.1196 7.77987 14.0343 7.7503C13.7229 7.64226 13.3178 7.43941 12.9389 7.06047C12.5599 6.68153 12.3571 6.27642 12.249 5.96501C12.2195 5.87979 12.1096 5.85059 12.0458 5.91438L9.40757 8.55265L9.40756 8.55265C9.19594 8.76427 9.09013 8.87009 8.99913 8.98676C8.89178 9.12438 8.79975 9.2733 8.72466 9.43086C8.661 9.56443 8.61368 9.70639 8.51904 9.99032L8.39675 10.3572L8.20229 10.9406L8.01996 11.4875C7.97339 11.6273 8.00976 11.7813 8.1139 11.8855C8.21804 11.9896 8.37209 12.026 8.51181 11.9794L9.0588 11.7971L9.64216 11.6026L10.009 11.4803L10.009 11.4803C10.293 11.3857 10.4349 11.3383 10.5685 11.2747C10.7261 11.1996 10.875 11.1076 11.0126 11.0002Z"
      fill="currentColor"
    />
    <path
      d="M14.911 7.12747C15.4741 6.56438 15.4741 5.65143 14.911 5.08833C14.3479 4.52524 13.435 4.52524 12.8719 5.08833L12.7871 5.17315C12.7052 5.255 12.6681 5.36932 12.6886 5.48325C12.7014 5.55492 12.7253 5.6597 12.7687 5.78473C12.8554 6.03477 13.0192 6.36301 13.3278 6.67156C13.6363 6.98012 13.9646 7.14394 14.2146 7.23069C14.3396 7.27406 14.4444 7.29791 14.5161 7.31077C14.63 7.33122 14.7443 7.29414 14.8262 7.21229L14.911 7.12747Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.78105 2.11503C2 2.89608 2 4.15316 2 6.66732V9.33398C2 11.8481 2 13.1052 2.78105 13.8863C3.5621 14.6673 4.81918 14.6673 7.33333 14.6673H8.66667C11.1808 14.6673 12.4379 14.6673 13.219 13.8863C13.9875 13.1178 13.9998 11.8884 14 9.45403L12.1211 11.3329C11.9413 11.5129 11.794 11.6603 11.6276 11.79C11.4327 11.942 11.2219 12.0724 10.9987 12.1787C10.8083 12.2695 10.6106 12.3353 10.3692 12.4157L8.82806 12.9294C8.32901 13.0957 7.7788 12.9658 7.40682 12.5939C7.03484 12.2219 6.90496 11.6717 7.07131 11.1726L7.25364 10.6256L7.57038 9.67539L7.58499 9.63152C7.66537 9.39011 7.7312 9.19239 7.82196 9.00194C7.9283 8.77881 8.05863 8.56793 8.21065 8.37304C8.3404 8.20668 8.48781 8.05937 8.66779 7.87952L11.3388 5.20857L12.08 4.46735L12.1648 4.38253C12.6417 3.90559 13.2669 3.66719 13.892 3.66732C13.7911 2.98029 13.5961 2.49217 13.219 2.11503C12.4379 1.33398 11.1808 1.33398 8.66667 1.33398H7.33333C4.81918 1.33398 3.5621 1.33398 2.78105 2.11503ZM4.83333 6.00065C4.83333 5.72451 5.05719 5.50065 5.33333 5.50065H9.66667C9.94281 5.50065 10.1667 5.72451 10.1667 6.00065C10.1667 6.27679 9.94281 6.50065 9.66667 6.50065H5.33333C5.05719 6.50065 4.83333 6.27679 4.83333 6.00065ZM4.83333 8.66732C4.83333 8.39118 5.05719 8.16732 5.33333 8.16732H7C7.27614 8.16732 7.5 8.39118 7.5 8.66732C7.5 8.94346 7.27614 9.16732 7 9.16732H5.33333C5.05719 9.16732 4.83333 8.94346 4.83333 8.66732ZM4.83333 11.334C4.83333 11.0578 5.05719 10.834 5.33333 10.834H6.33333C6.60948 10.834 6.83333 11.0578 6.83333 11.334C6.83333 11.6101 6.60948 11.834 6.33333 11.834H5.33333C5.05719 11.834 4.83333 11.6101 4.83333 11.334Z"
      fill="currentColor"
    />
  </svg>
</template>
