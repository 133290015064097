<!-- eslint-disable max-len -->
<template>
  <div class="schema-publish-progress-animation">
    <div class="schema-illustration">
      <svg
        width="126"
        height="164"
        viewBox="0 0 126 164"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M103.911 23.1739L91.1866 0H8.24459C3.69127 0 0 3.67184 0 8.2012V154.841C0 159.371 3.69127 163.042 8.24459 163.042H106.239C110.792 163.042 114.483 159.371 114.483 154.841V154.282H120.541V33.9536L103.911 23.1739Z"
          fill="#AFCDD0"
        />
        <path
          d="M114.483 30.0273V154.842C114.483 159.371 110.792 163.043 106.238 163.043H117.075C121.628 163.043 125.32 159.371 125.32 154.842V33.9543L114.483 30.0273Z"
          fill="#8CA4A6"
        />
        <g clip-path="url(#clip0_334_8885)">
          <path
            d="M34.437 126.204L14.1111 135.264C12.5697 135.264 11.3203 132.83 11.3203 129.828V56.2793C11.3203 53.2772 12.5697 50.8438 14.1111 50.8438H34.437L37.3266 84.6117L34.437 126.204Z"
            fill="#426D70"
          />
          <path
            d="M11.3203 126.205V129.829C11.3203 132.831 12.5697 135.264 14.1111 135.264H34.437L34.9539 126.205H11.3203Z"
            fill="#426D70"
          />
          <path
            d="M11.3203 126.205V129.829C11.3203 132.831 12.5697 135.264 14.1111 135.264H34.437L34.9539 126.205H11.3203Z"
            fill="black"
            fill-opacity="0.2"
          />
          <path
            d="M100.898 130.734L34.4375 135.264V109.841L36.298 107.124L34.4375 104.406V81.7014L36.298 78.9837L34.4375 76.2661V50.8438H56.1589L57.5542 54.4675L58.9495 50.8438H79.2755L80.6708 54.4675L82.0661 50.8438H103.788L100.898 130.734Z"
            fill="#DFEBEC"
          />
          <path
            d="M99.1362 50.8438V120.769C99.1362 123.771 97.8868 126.205 96.3454 126.205H34.4375V135.264H56.1589L57.5542 131.64L58.9495 135.264H79.2753L80.6708 131.64L82.0661 135.264H100.997C102.538 135.264 103.788 132.83 103.788 129.828V50.8438H99.1362Z"
            fill="#8CA4A6"
          />
          <path
            d="M11.3203 81.7009H37.0775L36.6125 76.2656H11.3203V81.7009ZM36.1973 104.406H11.3203V109.841H35.8872L36.1973 104.406Z"
            fill="#426D70"
          />
          <path
            d="M11.3203 81.7009H37.0775L36.6125 76.2656H11.3203V81.7009ZM36.1973 104.406H11.3203V109.841H35.8872L36.1973 104.406Z"
            fill="black"
            fill-opacity="0.2"
          />
          <path
            d="M25.7682 67.6306H19.9891C19.619 67.6306 19.2641 67.3442 19.0024 66.8346C18.7408 66.3249 18.5938 65.6337 18.5938 64.9129C18.5938 64.1922 18.7408 63.5009 19.0024 62.9913C19.2641 62.4816 19.619 62.1953 19.9891 62.1953H25.7682C26.1383 62.1953 26.4932 62.4816 26.7549 62.9913C27.0165 63.5009 27.1635 64.1922 27.1635 64.9129C27.1635 65.6337 27.0165 66.3249 26.7549 66.8346C26.4932 67.3442 26.1383 67.6306 25.7682 67.6306ZM25.7682 95.7706H19.9891C19.619 95.7706 19.2641 95.4842 19.0024 94.9746C18.7408 94.4649 18.5938 93.7737 18.5938 93.0529C18.5938 92.3322 18.7408 91.6409 19.0024 91.1313C19.2641 90.6216 19.619 90.3353 19.9891 90.3353H25.7682C26.1383 90.3353 26.4932 90.6216 26.7549 91.1313C27.0165 91.6409 27.1635 92.3322 27.1635 93.0529C27.1635 93.7737 27.0165 94.4649 26.7549 94.9746C26.4932 95.4842 26.1383 95.7706 25.7682 95.7706ZM25.7682 123.911H19.9891C19.619 123.911 19.2641 123.624 19.0024 123.115C18.7408 122.605 18.5938 121.914 18.5938 121.193C18.5938 120.472 18.7408 119.781 19.0024 119.271C19.2641 118.762 19.619 118.475 19.9891 118.475H25.7682C26.1383 118.475 26.4932 118.762 26.7549 119.271C27.0165 119.781 27.1635 120.472 27.1635 121.193C27.1635 121.914 27.0165 122.605 26.7549 123.115C26.4932 123.624 26.1383 123.911 25.7682 123.911Z"
            fill="white"
          />
          <path
            d="M49.0143 67.8845H43.2352C42.8651 67.8845 42.5102 67.5981 42.2485 67.0885C41.9868 66.5788 41.8398 65.8876 41.8398 65.1668C41.8398 64.4461 41.9868 63.7548 42.2485 63.2452C42.5102 62.7355 42.8651 62.4492 43.2352 62.4492H49.0143C49.3844 62.4492 49.7393 62.7355 50.0009 63.2452C50.2626 63.7548 50.4096 64.4461 50.4096 65.1668C50.4096 65.8876 50.2626 66.5788 50.0009 67.0885C49.7393 67.5981 49.3844 67.8845 49.0143 67.8845ZM49.0143 96.0245H43.2352C42.8651 96.0245 42.5102 95.7381 42.2485 95.2285C41.9868 94.7188 41.8398 94.0276 41.8398 93.3068C41.8398 92.5861 41.9868 91.8948 42.2485 91.3852C42.5102 90.8755 42.8651 90.5892 43.2352 90.5892H49.0143C49.3844 90.5892 49.7393 90.8755 50.0009 91.3852C50.2626 91.8948 50.4096 92.5861 50.4096 93.3068C50.4096 94.0276 50.2626 94.7188 50.0009 95.2285C49.7393 95.7381 49.3844 96.0245 49.0143 96.0245ZM49.0143 124.164H43.2352C42.8651 124.164 42.5102 123.878 42.2485 123.368C41.9868 122.859 41.8398 122.168 41.8398 121.447C41.8398 120.726 41.9868 120.035 42.2485 119.525C42.5102 119.016 42.8651 118.729 43.2352 118.729H49.0143C49.3844 118.729 49.7393 119.016 50.0009 119.525C50.2626 120.035 50.4096 120.726 50.4096 121.447C50.4096 122.168 50.2626 122.859 50.0009 123.368C49.7393 123.878 49.3844 124.164 49.0143 124.164Z"
            fill="#0D1616"
            fill-opacity="0.1"
          />
          <path
            d="M71.9518 67.8845H66.1727C65.8026 67.8845 65.4477 67.5981 65.186 67.0885C64.9243 66.5788 64.7773 65.8876 64.7773 65.1668C64.7773 64.4461 64.9243 63.7548 65.186 63.2452C65.4477 62.7355 65.8026 62.4492 66.1727 62.4492H71.9518C72.3219 62.4492 72.6768 62.7355 72.9384 63.2452C73.2001 63.7548 73.3471 64.4461 73.3471 65.1668C73.3471 65.8876 73.2001 66.5788 72.9384 67.0885C72.6768 67.5981 72.3219 67.8845 71.9518 67.8845ZM71.9518 96.0245H66.1727C65.8026 96.0245 65.4477 95.7381 65.186 95.2285C64.9243 94.7188 64.7773 94.0276 64.7773 93.3068C64.7773 92.5861 64.9243 91.8948 65.186 91.3852C65.4477 90.8755 65.8026 90.5892 66.1727 90.5892H71.9518C72.3219 90.5892 72.6768 90.8755 72.9384 91.3852C73.2001 91.8948 73.3471 92.5861 73.3471 93.3068C73.3471 94.0276 73.2001 94.7188 72.9384 95.2285C72.6768 95.7381 72.3219 96.0245 71.9518 96.0245ZM71.9518 124.164H66.1727C65.8026 124.164 65.4477 123.878 65.186 123.368C64.9243 122.859 64.7773 122.168 64.7773 121.447C64.7773 120.726 64.9243 120.035 65.186 119.525C65.4477 119.016 65.8026 118.729 66.1727 118.729H71.9518C72.3219 118.729 72.6768 119.016 72.9384 119.525C73.2001 120.035 73.3471 120.726 73.3471 121.447C73.3471 122.168 73.2001 122.859 72.9384 123.368C72.6768 123.878 72.3219 124.164 71.9518 124.164Z"
            fill="#0D1616"
            fill-opacity="0.5"
          />
          <path
            d="M93.4421 67.8845H87.6629C87.2928 67.8845 86.9379 67.5981 86.6763 67.0885C86.4146 66.5788 86.2676 65.8876 86.2676 65.1668C86.2676 64.4461 86.4146 63.7548 86.6763 63.2452C86.9379 62.7355 87.2928 62.4492 87.6629 62.4492H93.4421C93.8121 62.4492 94.167 62.7355 94.4287 63.2452C94.6904 63.7548 94.8374 64.4461 94.8374 65.1668C94.8374 65.8876 94.6904 66.5788 94.4287 67.0885C94.167 67.5981 93.8121 67.8845 93.4421 67.8845ZM93.4421 96.0245H87.6629C87.2928 96.0245 86.9379 95.7381 86.6763 95.2285C86.4146 94.7188 86.2676 94.0276 86.2676 93.3068C86.2676 92.5861 86.4146 91.8948 86.6763 91.3852C86.9379 90.8755 87.2928 90.5892 87.6629 90.5892H93.4421C93.8121 90.5892 94.167 90.8755 94.4287 91.3852C94.6904 91.8948 94.8374 92.5861 94.8374 93.3068C94.8374 94.0276 94.6904 94.7188 94.4287 95.2285C94.167 95.7381 93.8121 96.0245 93.4421 96.0245ZM93.4421 124.164H87.6629C87.2928 124.164 86.9379 123.878 86.6763 123.368C86.4146 122.859 86.2676 122.168 86.2676 121.447C86.2676 120.726 86.4146 120.035 86.6763 119.525C86.9379 119.016 87.2928 118.729 87.6629 118.729H93.4421C93.8121 118.729 94.167 119.016 94.4287 119.525C94.6904 120.035 94.8374 120.726 94.8374 121.447C94.8374 122.168 94.6904 122.859 94.4287 123.368C94.167 123.878 93.8121 124.164 93.4421 124.164Z"
            fill="#0D1616"
            fill-opacity="0.5"
          />
          <path
            d="M103.788 104.406H82.0661V81.7017H103.788V76.2661H82.0661V50.8438H79.2753V76.2661H58.9495V50.8438H56.1587V76.2661H34.4375V81.7017H56.1589V104.406H34.4375V109.842H56.1589V135.264H58.9497V109.841H79.2755V135.264H82.0663V109.841H103.788V104.406ZM79.2755 104.406H58.9495V81.7017H79.2753L79.2755 104.406Z"
            fill="#8CA4A6"
          />
        </g>
        <path
          d="M99.4301 33.9536H125.319L91.1855 0V25.7524C91.1855 30.2817 94.8768 33.9536 99.4301 33.9536Z"
          fill="#8CA4A6"
        />
        <path
          d="M99.4301 33.9536H125.319L91.1855 0V25.7524C91.1855 30.2817 94.8768 33.9536 99.4301 33.9536Z"
          fill="black"
          fill-opacity="0.1"
        />
        <defs>
          <clipPath id="clip0_334_8885">
            <rect
              width="92.4667"
              height="84.42"
              fill="white"
              transform="translate(11.3203 50.8438)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div
      class="schema-progress-loading-icon icon-rotate"
    >
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="29.2969"
          cy="29.2969"
          r="29.2969"
          fill="white"
        />
        <g clip-path="url(#clip0_334_8904)">
          <path
            d="M24.2969 16.8398C24.2969 19.7393 26.6474 22.0898 29.5469 22.0898C32.4464 22.0898 34.7969 19.7393 34.7969 16.8398C34.7969 13.9403 32.4464 11.5898 29.5469 11.5898C26.6474 11.5898 24.2969 13.9403 24.2969 16.8398Z"
            fill="#426D70"
          />
          <path
            d="M12.9219 37.8398C12.9219 40.7393 15.2724 43.0898 18.1719 43.0898C21.0714 43.0898 23.4219 40.7393 23.4219 37.8398C23.4219 34.9403 21.0714 32.5898 18.1719 32.5898C15.2724 32.5898 12.9219 34.9403 12.9219 37.8398Z"
            fill="#426D70"
          />
          <path
            d="M40.9219 43.0898C38.0224 43.0898 35.6719 40.7393 35.6719 37.8398C35.6719 34.9403 38.0224 32.5898 40.9219 32.5898C43.8214 32.5898 46.1719 34.9403 46.1719 37.8398C46.1719 40.7393 43.8214 43.0898 40.9219 43.0898Z"
            fill="#426D70"
          />
          <path
            d="M21.1551 19.5739C21.6985 19.0942 21.7502 18.2648 21.2706 17.7213C20.7909 17.1779 19.9615 17.1261 19.418 17.6058C16.2412 20.4097 14.2344 24.5162 14.2344 29.0899C14.2344 29.8148 14.822 30.4024 15.5469 30.4024C16.2717 30.4024 16.8594 29.8148 16.8594 29.0899C16.8594 25.3006 18.5189 21.9006 21.1551 19.5739Z"
            fill="#426D70"
          />
          <path
            d="M39.6757 17.6058C39.1323 17.1261 38.3028 17.1779 37.8232 17.7213C37.3435 18.2648 37.3952 19.0942 37.9387 19.5739C40.5749 21.9006 42.2344 25.3006 42.2344 29.0899C42.2344 29.8148 42.822 30.4024 43.5469 30.4024C44.2717 30.4024 44.8594 29.8148 44.8594 29.0899C44.8594 24.5162 42.8525 20.4097 39.6757 17.6058Z"
            fill="#426D70"
          />
          <path
            d="M26.374 41.3778C25.672 41.1971 24.9565 41.6197 24.7758 42.3217C24.5951 43.0237 25.0177 43.7392 25.7197 43.9199C26.9444 44.2351 28.227 44.4024 29.5469 44.4024C30.8667 44.4024 32.1494 44.2351 33.374 43.9199C34.076 43.7392 34.4986 43.0237 34.3179 42.3217C34.1373 41.6197 33.4217 41.1971 32.7197 41.3778C31.707 41.6384 30.6441 41.7774 29.5469 41.7774C28.4496 41.7774 27.3867 41.6384 26.374 41.3778Z"
            fill="#426D70"
          />
        </g>
        <defs>
          <clipPath id="clip0_334_8904">
            <rect
              width="45.1389"
              height="38.0859"
              fill="white"
              transform="translate(6.73828 7.61719)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.schema-illustration {
  width: 197px;
  height: 190px;
  display: flex;
  justify-content: center;
}

.schema-publish-progress-animation {
  width: 198px;
  margin: 0 auto;
  position: relative;

  .schema-progress-loading-icon {
    position: absolute;
    bottom: -3px;
    right: 27px;
  }
}
</style>
