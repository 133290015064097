<!-- eslint-disable max-len -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.12868 2.37868C2.25 3.25736 2.25 4.67157 2.25 7.5V10.5C2.25 13.3284 2.25 14.7426 3.12868 15.6213C4.00736 16.5 5.42157 16.5 8.25 16.5H9.75C12.5784 16.5 13.9926 16.5 14.8713 15.6213C15.75 14.7426 15.75 13.3284 15.75 10.5V7.5C15.75 4.67157 15.75 3.25736 14.8713 2.37868C13.9926 1.5 12.5784 1.5 9.75 1.5H8.25C5.42157 1.5 4.00736 1.5 3.12868 2.37868ZM6 6.9375C5.68934 6.9375 5.4375 7.18934 5.4375 7.5C5.4375 7.81066 5.68934 8.0625 6 8.0625H12C12.3107 8.0625 12.5625 7.81066 12.5625 7.5C12.5625 7.18934 12.3107 6.9375 12 6.9375H6ZM6 9.9375C5.68934 9.9375 5.4375 10.1893 5.4375 10.5C5.4375 10.8107 5.68934 11.0625 6 11.0625H9.75C10.0607 11.0625 10.3125 10.8107 10.3125 10.5C10.3125 10.1893 10.0607 9.9375 9.75 9.9375H6Z"
      fill="currentColor"
    />
  </svg>
</template>
