<!-- eslint-disable max-len -->
<template>
  <svg
    width="95"
    height="82"
    viewBox="0 0 95 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_340_8900)">
      <path
        d="M88.2545 67.2422C88.2363 67.2154 88.2193 67.1883 88.2024 67.1607L52.1063 8.63589C49.9667 5.16797 45.0269 5.17367 42.8907 8.63608L6.63971 67.4124C4.35471 71.1176 6.96349 75.96 11.2474 75.96H83.7556C88.1345 75.96 90.7132 70.9189 88.2545 67.2422Z"
        fill="#E11900"
      />
      <path
        d="M10.8218 73.0796C10.3512 72.2065 10.1181 71.2203 10.1467 70.2238C10.1754 69.2272 10.4648 68.2567 10.9847 67.4135L47.2359 8.6373C47.8184 7.68969 48.6673 6.94429 49.6712 6.49884C47.2542 5.42696 44.3608 6.25436 42.8907 8.6373L6.63971 67.4135C4.3547 71.1186 6.96349 75.961 11.2474 75.961H15.5925C14.6181 75.961 13.6616 75.6936 12.8225 75.1868C11.9834 74.6801 11.2925 73.9524 10.8218 73.0796Z"
        fill="#B41400"
      />
      <path
        d="M83.7542 81.8798H11.2462C2.4168 81.8798 -2.96664 71.8979 1.74587 64.2559L37.9974 5.47989C42.4074 -1.66909 52.5935 -1.66112 56.9977 5.47951L93.0655 63.9579C98.0932 71.5659 92.7326 81.8798 83.7542 81.8798ZM43.2929 8.89825L7.04192 67.6743C4.95693 71.0553 7.33731 75.474 11.2464 75.474H83.7544C87.747 75.474 90.1051 70.877 87.8618 67.5219C87.8399 67.4893 87.8187 67.4562 87.7982 67.4226L51.702 8.89806C49.7497 5.73367 45.2422 5.73841 43.2929 8.89825Z"
        fill="#E11900"
      />
      <path
        d="M83.7542 81.8798H11.2462C2.4168 81.8798 -2.96664 71.8979 1.74587 64.2559L37.9974 5.47989C42.4074 -1.66909 52.5935 -1.66112 56.9977 5.47951L93.0655 63.9579C98.0932 71.5659 92.7326 81.8798 83.7542 81.8798ZM43.2929 8.89825L7.04192 67.6743C4.95693 71.0553 7.33731 75.474 11.2464 75.474H83.7544C87.747 75.474 90.1051 70.877 87.8618 67.5219C87.8399 67.4893 87.8187 67.4562 87.7982 67.4226L51.702 8.89806C49.7497 5.73367 45.2422 5.73841 43.2929 8.89825Z"
        fill="white"
        fill-opacity="0.25"
      />
      <path
        d="M5.55977 64.2558L41.8112 5.47942C43.5364 2.68288 46.2901 0.826677 49.4043 0.284945C44.9482 -0.489499 40.462 1.48363 37.9972 5.47942L1.74567 64.2558C-2.96536 71.8952 2.41364 81.8797 11.246 81.8797H15.06C6.23071 81.8795 0.847255 71.8975 5.55977 64.2558Z"
        fill="#E11900"
      />
      <path
        d="M5.55977 64.2558L41.8112 5.47942C43.5364 2.68288 46.2901 0.826677 49.4043 0.284945C44.9482 -0.489499 40.462 1.48363 37.9972 5.47942L1.74567 64.2558C-2.96536 71.8952 2.41364 81.8797 11.246 81.8797H15.06C6.23071 81.8795 0.847255 71.8975 5.55977 64.2558Z"
        fill="black"
        fill-opacity="0.1"
      />
      <path
        d="M47.4981 27.5723C45.7694 27.5723 44.3672 29.0063 44.3672 30.7752V49.3746C44.3672 51.1435 45.7694 52.5775 47.4981 52.5775C49.2276 52.5775 50.6292 51.1435 50.6292 49.3746V30.7752C50.6292 29.0063 49.2276 27.5723 47.4981 27.5723Z"
        fill="#F2F2F2"
      />
      <path
        d="M47.4981 64.9352C49.2273 64.9352 50.629 63.5012 50.629 61.7322C50.629 59.9633 49.2273 58.5293 47.4981 58.5293C45.7689 58.5293 44.3672 59.9633 44.3672 61.7322C44.3672 63.5012 45.7689 64.9352 47.4981 64.9352Z"
        fill="#F2F2F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_340_8900">
        <rect
          width="95"
          height="82"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
