<!-- eslint-disable max-len -->
<template>
  <svg
    width="145"
    height="194"
    viewBox="0 0 145 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.591 23.252L91.8663 0.078125H8.92428C4.37095 0.078125 0.679688 3.74997 0.679688 8.27932V154.919C0.679688 159.449 4.37095 163.121 8.92428 163.121H106.918C111.472 163.121 115.163 159.449 115.163 154.919V154.36H121.221V34.0317L104.591 23.252Z"
      fill="#AFCDD0"
    />
    <path
      d="M115.163 30.1055V154.92C115.163 159.449 111.471 163.121 106.918 163.121H117.755C122.308 163.121 125.999 159.449 125.999 154.92V34.0324L115.163 30.1055Z"
      fill="#8CA4A6"
    />
    <g clip-path="url(#clip0_0_1)">
      <path
        d="M35.1167 126.283L14.7908 135.342C13.2494 135.342 12 132.908 12 129.906V56.3575C12 53.3553 13.2494 50.9219 14.7908 50.9219H35.1167L38.0063 84.6899L35.1167 126.283Z"
        fill="#426D70"
      />
      <path
        d="M12 126.283V129.907C12 132.909 13.2494 135.343 14.7908 135.343H35.1167L35.6335 126.283H12Z"
        fill="#426D70"
      />
      <path
        d="M12 126.283V129.907C12 132.909 13.2494 135.343 14.7908 135.343H35.1167L35.6335 126.283H12Z"
        fill="black"
        fill-opacity="0.2"
      />
      <path
        d="M101.578 130.812L35.1172 135.342V109.919L36.9777 107.202L35.1172 104.484V81.7795L36.9777 79.0619L35.1172 76.3443V50.9219H56.8385L58.2339 54.5456L59.6292 50.9219H79.9552L81.3505 54.5456L82.7458 50.9219H104.467L101.578 130.812Z"
        fill="#DFEBEC"
      />
      <path
        d="M99.8159 50.9219V120.847C99.8159 123.849 98.5665 126.283 97.0251 126.283H35.1172V135.342H56.8385L58.2339 131.718L59.6292 135.342H79.955L81.3505 131.718L82.7458 135.342H101.676C103.218 135.342 104.467 132.908 104.467 129.906V50.9219H99.8159Z"
        fill="#8CA4A6"
      />
      <path
        d="M12 81.779H37.7572L37.2922 76.3438H12V81.779ZM36.877 104.484H12V109.919H36.5669L36.877 104.484Z"
        fill="#426D70"
      />
      <path
        d="M12 81.779H37.7572L37.2922 76.3438H12V81.779ZM36.877 104.484H12V109.919H36.5669L36.877 104.484Z"
        fill="black"
        fill-opacity="0.2"
      />
      <path
        d="M26.4479 67.7087H20.6687C20.2987 67.7087 19.9438 67.4224 19.6821 66.9127C19.4204 66.4031 19.2734 65.7118 19.2734 64.9911C19.2734 64.2703 19.4204 63.5791 19.6821 63.0694C19.9438 62.5598 20.2987 62.2734 20.6687 62.2734H26.4479C26.818 62.2734 27.1729 62.5598 27.4345 63.0694C27.6962 63.5791 27.8432 64.2703 27.8432 64.9911C27.8432 65.7118 27.6962 66.4031 27.4345 66.9127C27.1729 67.4224 26.818 67.7087 26.4479 67.7087ZM26.4479 95.8487H20.6687C20.2987 95.8487 19.9438 95.5624 19.6821 95.0527C19.4204 94.543 19.2734 93.8518 19.2734 93.1311C19.2734 92.4103 19.4204 91.7191 19.6821 91.2094C19.9438 90.6998 20.2987 90.4134 20.6687 90.4134H26.4479C26.818 90.4134 27.1729 90.6998 27.4345 91.2094C27.6962 91.7191 27.8432 92.4103 27.8432 93.1311C27.8432 93.8518 27.6962 94.543 27.4345 95.0527C27.1729 95.5624 26.818 95.8487 26.4479 95.8487ZM26.4479 123.989H20.6687C20.2987 123.989 19.9438 123.702 19.6821 123.193C19.4204 122.683 19.2734 121.992 19.2734 121.271C19.2734 120.55 19.4204 119.859 19.6821 119.349C19.9438 118.84 20.2987 118.553 20.6687 118.553H26.4479C26.818 118.553 27.1729 118.84 27.4345 119.349C27.6962 119.859 27.8432 120.55 27.8432 121.271C27.8432 121.992 27.6962 122.683 27.4345 123.193C27.1729 123.702 26.818 123.989 26.4479 123.989Z"
        fill="white"
      />
      <path
        d="M49.694 67.9626H43.9148C43.5448 67.9626 43.1899 67.6763 42.9282 67.1666C42.6665 66.657 42.5195 65.9657 42.5195 65.245C42.5195 64.5242 42.6665 63.833 42.9282 63.3233C43.1899 62.8137 43.5448 62.5273 43.9148 62.5273H49.694C50.0641 62.5273 50.419 62.8137 50.6806 63.3233C50.9423 63.833 51.0893 64.5242 51.0893 65.245C51.0893 65.9657 50.9423 66.657 50.6806 67.1666C50.419 67.6763 50.0641 67.9626 49.694 67.9626ZM49.694 96.1026H43.9148C43.5448 96.1026 43.1899 95.8163 42.9282 95.3066C42.6665 94.797 42.5195 94.1057 42.5195 93.385C42.5195 92.6642 42.6665 91.973 42.9282 91.4633C43.1899 90.9537 43.5448 90.6673 43.9148 90.6673H49.694C50.0641 90.6673 50.419 90.9537 50.6806 91.4633C50.9423 91.973 51.0893 92.6642 51.0893 93.385C51.0893 94.1057 50.9423 94.797 50.6806 95.3066C50.419 95.8163 50.0641 96.1026 49.694 96.1026ZM49.694 124.243H43.9148C43.5448 124.243 43.1899 123.956 42.9282 123.447C42.6665 122.937 42.5195 122.246 42.5195 121.525C42.5195 120.804 42.6665 120.113 42.9282 119.603C43.1899 119.094 43.5448 118.807 43.9148 118.807H49.694C50.0641 118.807 50.419 119.094 50.6806 119.603C50.9423 120.113 51.0893 120.804 51.0893 121.525C51.0893 122.246 50.9423 122.937 50.6806 123.447C50.419 123.956 50.0641 124.243 49.694 124.243Z"
        fill="#0D1616"
        fill-opacity="0.1"
      />
      <path
        d="M72.6315 67.9626H66.8523C66.4823 67.9626 66.1274 67.6763 65.8657 67.1666C65.604 66.657 65.457 65.9657 65.457 65.245C65.457 64.5242 65.604 63.833 65.8657 63.3233C66.1274 62.8137 66.4823 62.5273 66.8523 62.5273H72.6315C73.0016 62.5273 73.3565 62.8137 73.6181 63.3233C73.8798 63.833 74.0268 64.5242 74.0268 65.245C74.0268 65.9657 73.8798 66.657 73.6181 67.1666C73.3565 67.6763 73.0016 67.9626 72.6315 67.9626ZM72.6315 96.1026H66.8523C66.4823 96.1026 66.1274 95.8163 65.8657 95.3066C65.604 94.797 65.457 94.1057 65.457 93.385C65.457 92.6642 65.604 91.973 65.8657 91.4633C66.1274 90.9537 66.4823 90.6673 66.8523 90.6673H72.6315C73.0016 90.6673 73.3565 90.9537 73.6181 91.4633C73.8798 91.973 74.0268 92.6642 74.0268 93.385C74.0268 94.1057 73.8798 94.797 73.6181 95.3066C73.3565 95.8163 73.0016 96.1026 72.6315 96.1026ZM72.6315 124.243H66.8523C66.4823 124.243 66.1274 123.956 65.8657 123.447C65.604 122.937 65.457 122.246 65.457 121.525C65.457 120.804 65.604 120.113 65.8657 119.603C66.1274 119.094 66.4823 118.807 66.8523 118.807H72.6315C73.0016 118.807 73.3565 119.094 73.6181 119.603C73.8798 120.113 74.0268 120.804 74.0268 121.525C74.0268 122.246 73.8798 122.937 73.6181 123.447C73.3565 123.956 73.0016 124.243 72.6315 124.243Z"
        fill="#0D1616"
        fill-opacity="0.5"
      />
      <path
        d="M94.1217 67.9626H88.3426C87.9725 67.9626 87.6176 67.6763 87.3559 67.1666C87.0943 66.657 86.9473 65.9657 86.9473 65.245C86.9473 64.5242 87.0943 63.833 87.3559 63.3233C87.6176 62.8137 87.9725 62.5273 88.3426 62.5273H94.1217C94.4918 62.5273 94.8467 62.8137 95.1084 63.3233C95.37 63.833 95.517 64.5242 95.517 65.245C95.517 65.9657 95.37 66.657 95.1084 67.1666C94.8467 67.6763 94.4918 67.9626 94.1217 67.9626ZM94.1217 96.1026H88.3426C87.9725 96.1026 87.6176 95.8163 87.3559 95.3066C87.0943 94.797 86.9473 94.1057 86.9473 93.385C86.9473 92.6642 87.0943 91.973 87.3559 91.4633C87.6176 90.9537 87.9725 90.6673 88.3426 90.6673H94.1217C94.4918 90.6673 94.8467 90.9537 95.1084 91.4633C95.37 91.973 95.517 92.6642 95.517 93.385C95.517 94.1057 95.37 94.797 95.1084 95.3066C94.8467 95.8163 94.4918 96.1026 94.1217 96.1026ZM94.1217 124.243H88.3426C87.9725 124.243 87.6176 123.956 87.3559 123.447C87.0943 122.937 86.9473 122.246 86.9473 121.525C86.9473 120.804 87.0943 120.113 87.3559 119.603C87.6176 119.094 87.9725 118.807 88.3426 118.807H94.1217C94.4918 118.807 94.8467 119.094 95.1084 119.603C95.37 120.113 95.517 120.804 95.517 121.525C95.517 122.246 95.37 122.937 95.1084 123.447C94.8467 123.956 94.4918 124.243 94.1217 124.243Z"
        fill="#0D1616"
        fill-opacity="0.5"
      />
      <path
        d="M104.467 104.484H82.7458V81.7798H104.467V76.3443H82.7458V50.9219H79.955V76.3443H59.6292V50.9219H56.8384V76.3443H35.1172V81.7798H56.8385V104.484H35.1172V109.92H56.8385V135.342H59.6293V109.919H79.9552V135.342H82.746V109.919H104.467V104.484ZM79.9552 104.484H59.6292V81.7798H79.955L79.9552 104.484Z"
        fill="#8CA4A6"
      />
    </g>
    <path
      d="M100.11 34.0317H125.998L91.8652 0.078125V25.8305C91.8652 30.3598 95.5565 34.0317 100.11 34.0317Z"
      fill="#8CA4A6"
    />
    <path
      d="M100.11 34.0317H125.998L91.8652 0.078125V25.8305C91.8652 30.3598 95.5565 34.0317 100.11 34.0317Z"
      fill="black"
      fill-opacity="0.1"
    />
    <g filter="url(#filter0_d_0_1)">
      <circle
        cx="111.297"
        cy="156.297"
        r="29.2969"
        fill="white"
      />
      <g clip-path="url(#clip1_0_1)">
        <path
          d="M106.297 143.84C106.297 146.739 108.647 149.09 111.547 149.09C114.446 149.09 116.797 146.739 116.797 143.84C116.797 140.94 114.446 138.59 111.547 138.59C108.647 138.59 106.297 140.94 106.297 143.84Z"
          fill="#426D70"
        />
        <path
          d="M94.9219 164.84C94.9219 167.739 97.2724 170.09 100.172 170.09C103.071 170.09 105.422 167.739 105.422 164.84C105.422 161.94 103.071 159.59 100.172 159.59C97.2724 159.59 94.9219 161.94 94.9219 164.84Z"
          fill="#426D70"
        />
        <path
          d="M122.922 170.09C120.022 170.09 117.672 167.739 117.672 164.84C117.672 161.94 120.022 159.59 122.922 159.59C125.821 159.59 128.172 161.94 128.172 164.84C128.172 167.739 125.821 170.09 122.922 170.09Z"
          fill="#426D70"
        />
        <path
          d="M103.155 146.574C103.699 146.094 103.75 145.265 103.271 144.721C102.791 144.178 101.961 144.126 101.418 144.606C98.2412 147.41 96.2344 151.516 96.2344 156.09C96.2344 156.815 96.822 157.402 97.5469 157.402C98.2717 157.402 98.8594 156.815 98.8594 156.09C98.8594 152.301 100.519 148.901 103.155 146.574Z"
          fill="#426D70"
        />
        <path
          d="M121.676 144.606C121.132 144.126 120.303 144.178 119.823 144.721C119.344 145.265 119.395 146.094 119.939 146.574C122.575 148.901 124.234 152.301 124.234 156.09C124.234 156.815 124.822 157.402 125.547 157.402C126.272 157.402 126.859 156.815 126.859 156.09C126.859 151.516 124.853 147.41 121.676 144.606Z"
          fill="#426D70"
        />
        <path
          d="M108.374 168.378C107.672 168.197 106.956 168.62 106.776 169.322C106.595 170.024 107.018 170.739 107.72 170.92C108.944 171.235 110.227 171.402 111.547 171.402C112.867 171.402 114.149 171.235 115.374 170.92C116.076 170.739 116.499 170.024 116.318 169.322C116.137 168.62 115.422 168.197 114.72 168.378C113.707 168.638 112.644 168.777 111.547 168.777C110.45 168.777 109.387 168.638 108.374 168.378Z"
          fill="#426D70"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_0_1"
        x="78"
        y="127"
        width="66.5938"
        height="66.5938"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_0_1">
        <rect
          width="92.4667"
          height="84.42"
          fill="white"
          transform="translate(12 50.9219)"
        />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect
          width="45.1389"
          height="38.0859"
          fill="white"
          transform="translate(88.7383 134.617)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
