<!-- eslint-disable max-len -->
<template>
  <div class="schema-assigning-progress-animation">
    <svg
      width="197"
      height="190"
      viewBox="0 0 197 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.911 23.1739L147.187 0H64.2446C59.6913 0 56 3.67184 56 8.2012V154.841C56 159.371 59.6913 163.042 64.2446 163.042H162.239C166.792 163.042 170.483 159.371 170.483 154.841V154.282H176.541V33.9536L159.911 23.1739Z"
        fill="#AFCDD0"
      />
      <path
        d="M170.483 30.0273V154.842C170.483 159.371 166.792 163.043 162.238 163.043H173.075C177.628 163.043 181.32 159.371 181.32 154.842V33.9543L170.483 30.0273Z"
        fill="#8CA4A6"
      />
      <g clip-path="url(#clip0_338_8971)">
        <path
          d="M90.437 126.204L70.1111 135.264C68.5697 135.264 67.3203 132.83 67.3203 129.828V56.2793C67.3203 53.2772 68.5697 50.8438 70.1111 50.8438H90.437L93.3266 84.6117L90.437 126.204Z"
          fill="#426D70"
        />
        <path
          d="M67.3203 126.205V129.829C67.3203 132.831 68.5697 135.264 70.1111 135.264H90.437L90.9539 126.205H67.3203Z"
          fill="#426D70"
        />
        <path
          d="M67.3203 126.205V129.829C67.3203 132.831 68.5697 135.264 70.1111 135.264H90.437L90.9539 126.205H67.3203Z"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M156.898 130.734L90.4375 135.264V109.841L92.298 107.124L90.4375 104.406V81.7014L92.298 78.9837L90.4375 76.2661V50.8438H112.159L113.554 54.4675L114.949 50.8438H135.276L136.671 54.4675L138.066 50.8438H159.787L156.898 130.734Z"
          fill="#DFEBEC"
        />
        <path
          d="M155.136 50.8438V120.769C155.136 123.771 153.887 126.205 152.345 126.205H90.4375V135.264H112.159L113.554 131.64L114.949 135.264H135.275L136.671 131.64L138.066 135.264H156.997C158.538 135.264 159.787 132.83 159.787 129.828V50.8438H155.136Z"
          fill="#8CA4A6"
        />
        <path
          d="M67.3203 81.7009H93.0775L92.6125 76.2656H67.3203V81.7009ZM92.1973 104.406H67.3203V109.841H91.8872L92.1973 104.406Z"
          fill="#426D70"
        />
        <path
          d="M67.3203 81.7009H93.0775L92.6125 76.2656H67.3203V81.7009ZM92.1973 104.406H67.3203V109.841H91.8872L92.1973 104.406Z"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M81.7682 67.6306H75.9891C75.619 67.6306 75.2641 67.3442 75.0024 66.8346C74.7408 66.3249 74.5938 65.6337 74.5938 64.9129C74.5938 64.1922 74.7408 63.5009 75.0024 62.9913C75.2641 62.4816 75.619 62.1953 75.9891 62.1953H81.7682C82.1383 62.1953 82.4932 62.4816 82.7549 62.9913C83.0165 63.5009 83.1635 64.1922 83.1635 64.9129C83.1635 65.6337 83.0165 66.3249 82.7549 66.8346C82.4932 67.3442 82.1383 67.6306 81.7682 67.6306ZM81.7682 95.7706H75.9891C75.619 95.7706 75.2641 95.4842 75.0024 94.9746C74.7408 94.4649 74.5938 93.7737 74.5938 93.0529C74.5938 92.3322 74.7408 91.6409 75.0024 91.1313C75.2641 90.6216 75.619 90.3353 75.9891 90.3353H81.7682C82.1383 90.3353 82.4932 90.6216 82.7549 91.1313C83.0165 91.6409 83.1635 92.3322 83.1635 93.0529C83.1635 93.7737 83.0165 94.4649 82.7549 94.9746C82.4932 95.4842 82.1383 95.7706 81.7682 95.7706ZM81.7682 123.911H75.9891C75.619 123.911 75.2641 123.624 75.0024 123.115C74.7408 122.605 74.5938 121.914 74.5938 121.193C74.5938 120.472 74.7408 119.781 75.0024 119.271C75.2641 118.762 75.619 118.475 75.9891 118.475H81.7682C82.1383 118.475 82.4932 118.762 82.7549 119.271C83.0165 119.781 83.1635 120.472 83.1635 121.193C83.1635 121.914 83.0165 122.605 82.7549 123.115C82.4932 123.624 82.1383 123.911 81.7682 123.911Z"
          fill="white"
        />
        <path
          d="M105.014 67.8845H99.2352C98.8651 67.8845 98.5102 67.5981 98.2485 67.0885C97.9868 66.5788 97.8398 65.8876 97.8398 65.1668C97.8398 64.4461 97.9868 63.7548 98.2485 63.2452C98.5102 62.7355 98.8651 62.4492 99.2352 62.4492H105.014C105.384 62.4492 105.739 62.7355 106.001 63.2452C106.263 63.7548 106.41 64.4461 106.41 65.1668C106.41 65.8876 106.263 66.5788 106.001 67.0885C105.739 67.5981 105.384 67.8845 105.014 67.8845ZM105.014 96.0245H99.2352C98.8651 96.0245 98.5102 95.7381 98.2485 95.2285C97.9868 94.7188 97.8398 94.0276 97.8398 93.3068C97.8398 92.5861 97.9868 91.8948 98.2485 91.3852C98.5102 90.8755 98.8651 90.5892 99.2352 90.5892H105.014C105.384 90.5892 105.739 90.8755 106.001 91.3852C106.263 91.8948 106.41 92.5861 106.41 93.3068C106.41 94.0276 106.263 94.7188 106.001 95.2285C105.739 95.7381 105.384 96.0245 105.014 96.0245ZM105.014 124.164H99.2352C98.8651 124.164 98.5102 123.878 98.2485 123.368C97.9868 122.859 97.8398 122.168 97.8398 121.447C97.8398 120.726 97.9868 120.035 98.2485 119.525C98.5102 119.016 98.8651 118.729 99.2352 118.729H105.014C105.384 118.729 105.739 119.016 106.001 119.525C106.263 120.035 106.41 120.726 106.41 121.447C106.41 122.168 106.263 122.859 106.001 123.368C105.739 123.878 105.384 124.164 105.014 124.164Z"
          fill="#0D1616"
          fill-opacity="0.1"
        />
        <path
          d="M127.952 67.8845H122.173C121.803 67.8845 121.448 67.5981 121.186 67.0885C120.924 66.5788 120.777 65.8876 120.777 65.1668C120.777 64.4461 120.924 63.7548 121.186 63.2452C121.448 62.7355 121.803 62.4492 122.173 62.4492H127.952C128.322 62.4492 128.677 62.7355 128.938 63.2452C129.2 63.7548 129.347 64.4461 129.347 65.1668C129.347 65.8876 129.2 66.5788 128.938 67.0885C128.677 67.5981 128.322 67.8845 127.952 67.8845ZM127.952 96.0245H122.173C121.803 96.0245 121.448 95.7381 121.186 95.2285C120.924 94.7188 120.777 94.0276 120.777 93.3068C120.777 92.5861 120.924 91.8948 121.186 91.3852C121.448 90.8755 121.803 90.5892 122.173 90.5892H127.952C128.322 90.5892 128.677 90.8755 128.938 91.3852C129.2 91.8948 129.347 92.5861 129.347 93.3068C129.347 94.0276 129.2 94.7188 128.938 95.2285C128.677 95.7381 128.322 96.0245 127.952 96.0245ZM127.952 124.164H122.173C121.803 124.164 121.448 123.878 121.186 123.368C120.924 122.859 120.777 122.168 120.777 121.447C120.777 120.726 120.924 120.035 121.186 119.525C121.448 119.016 121.803 118.729 122.173 118.729H127.952C128.322 118.729 128.677 119.016 128.938 119.525C129.2 120.035 129.347 120.726 129.347 121.447C129.347 122.168 129.2 122.859 128.938 123.368C128.677 123.878 128.322 124.164 127.952 124.164Z"
          fill="#0D1616"
          fill-opacity="0.5"
        />
        <path
          d="M149.442 67.8845H143.663C143.293 67.8845 142.938 67.5981 142.676 67.0885C142.415 66.5788 142.268 65.8876 142.268 65.1668C142.268 64.4461 142.415 63.7548 142.676 63.2452C142.938 62.7355 143.293 62.4492 143.663 62.4492H149.442C149.812 62.4492 150.167 62.7355 150.429 63.2452C150.69 63.7548 150.837 64.4461 150.837 65.1668C150.837 65.8876 150.69 66.5788 150.429 67.0885C150.167 67.5981 149.812 67.8845 149.442 67.8845ZM149.442 96.0245H143.663C143.293 96.0245 142.938 95.7381 142.676 95.2285C142.415 94.7188 142.268 94.0276 142.268 93.3068C142.268 92.5861 142.415 91.8948 142.676 91.3852C142.938 90.8755 143.293 90.5892 143.663 90.5892H149.442C149.812 90.5892 150.167 90.8755 150.429 91.3852C150.69 91.8948 150.837 92.5861 150.837 93.3068C150.837 94.0276 150.69 94.7188 150.429 95.2285C150.167 95.7381 149.812 96.0245 149.442 96.0245ZM149.442 124.164H143.663C143.293 124.164 142.938 123.878 142.676 123.368C142.415 122.859 142.268 122.168 142.268 121.447C142.268 120.726 142.415 120.035 142.676 119.525C142.938 119.016 143.293 118.729 143.663 118.729H149.442C149.812 118.729 150.167 119.016 150.429 119.525C150.69 120.035 150.837 120.726 150.837 121.447C150.837 122.168 150.69 122.859 150.429 123.368C150.167 123.878 149.812 124.164 149.442 124.164Z"
          fill="#0D1616"
          fill-opacity="0.5"
        />
        <path
          d="M159.787 104.406H138.066V81.7017H159.787V76.2661H138.066V50.8438H135.275V76.2661H114.949V50.8438H112.159V76.2661H90.4375V81.7017H112.159V104.406H90.4375V109.842H112.159V135.264H114.95V109.841H135.276V135.264H138.066V109.841H159.787V104.406ZM135.276 104.406H114.949V81.7017H135.275L135.276 104.406Z"
          fill="#8CA4A6"
        />
      </g>
      <path
        d="M155.43 33.9536H181.319L147.186 0V25.7524C147.186 30.2817 150.877 33.9536 155.43 33.9536Z"
        fill="#8CA4A6"
      />
      <path
        d="M155.43 33.9536H181.319L147.186 0V25.7524C147.186 30.2817 150.877 33.9536 155.43 33.9536Z"
        fill="black"
        fill-opacity="0.1"
      />
      <circle
        cx="167.297"
        cy="160.297"
        r="29.2969"
        fill="white"
      />
      <path
        d="M105.967 170.999C110.508 170.999 114.19 167.328 114.19 162.8V162.621H119.351V41.9446L102.383 29.3676L90.9532 8H8.22348C3.68182 8 0 11.6709 0 16.199V162.8C0 167.328 3.68182 170.999 8.22348 170.999H105.967Z"
        fill="#F7FAFA"
      />
      <path
        d="M16.5377 54H62.6017C64.1445 54 65.3946 52.4329 65.3946 50.5C65.3946 48.5671 64.1445 47 62.6017 47H16.5377C15.7969 47 15.0866 47.3687 14.5628 48.0251C14.039 48.6815 13.7448 49.5717 13.7448 50.5C13.7448 51.4283 14.039 52.3185 14.5628 52.9749C15.0866 53.6313 15.7969 54 16.5377 54ZM16.5377 131C14.9949 131 13.7448 132.567 13.7448 134.5C13.7448 136.433 14.9949 138 16.5377 138H62.6017C64.1445 138 65.3946 136.433 65.3946 134.5C65.3946 132.567 64.1445 131 62.6017 131H16.5377ZM99.2071 75H15.7929C14.2501 75 13 76.5671 13 78.5C13 80.4329 14.2501 82 15.7929 82H99.2071C100.75 82 102 80.4329 102 78.5C102 76.5671 100.75 75 99.2071 75ZM102 106.5C102 105.572 101.706 104.681 101.182 104.025C100.658 103.369 99.9478 103 99.2071 103H15.7929C14.2501 103 13 104.567 13 106.5C13 108.433 14.2501 110 15.7929 110H99.2071C99.9478 110 100.658 109.631 101.182 108.975C101.706 108.319 102 107.428 102 106.5Z"
        fill="#426D70"
      />
      <path
        d="M114.209 31V162.791C114.209 167.325 110.534 171 106 171H116.791C121.324 171 125 167.325 125 162.791V41.7898L114.209 31Z"
        fill="#DFEBEC"
      />
      <path
        d="M98.454 42H125L90 8V33.7876C89.9996 38.3231 93.7846 42 98.454 42Z"
        fill="#DFEBEC"
      />
      <path
        d="M98.454 42H125L90 8V33.7876C89.9996 38.3231 93.7846 42 98.454 42Z"
        fill="#426D70"
      />
      <defs>
        <clipPath id="clip0_338_8971">
          <rect
            width="92.4667"
            height="84.42"
            fill="white"
            transform="translate(67.3203 50.8438)"
          />
        </clipPath>
      </defs>
    </svg>
    <div
      class="schema-progress-loading-icon"
    >
      <img
        src="@/assets/LinkingAnimation.gif"
        alt="Loading document type assignment animation"
      >
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.schema-assigning-progress-animation {
  position: relative;
  width: 198px;
  margin: 0 auto;

  .schema-progress-loading-icon {
    img {
     width: 44px;
    }

    position: absolute;
    bottom: 9px;
    right: 9px
  }
}

</style>
